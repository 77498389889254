import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoIosDownload,
  IoMdOpen,
  IoIosCalendar,
  IoIosMail,
  IoIosContacts,
  IoIosImages,
  IoIosStats,
  IoIosArrowForward,
} from "react-icons/io";
import Image from "../components/image";

import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PressReleaseList = styled(Row)`
  --item-gap: 60px;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const PressReleaseCard = styled(Box)`
  /* padding: 40px; */
  text-align: center;

  .gatsby-image-wrapper {
    height: 200px;
    border-radius: ${(props) => props.theme.styles.borderRadius};
  }

  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 30px 0;
  }
`;

const PayrollStatsBanner = styled(Container)`
  text-align: center;
  background-color: ${(props) => props.theme.colours.PayHeroPrimary};
  padding: 20px;

  svg {
    position: relative;
    top: 2px;
    margin-right: 10px;
    color: ${(props) => props.theme.colours.white};
  }

  a {
    color: ${(props) => props.theme.colours.white};
  }
`;

const Media = (props) => {
  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulNewsArticle(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            description {
              description
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            linkTo
            date(formatString: "MMMM D, YYYY")
            source
          }
        }
      }
      allContentfulMediaRelease(sort: { fields: date, order: DESC }) {
        edges {
          node {
            url
            date(formatString: "MMMM D, YYYY")
            title
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="News & Media | FlexiTime"
        description="FlexiTime is an innovative software provider that is recognised for payroll, billing & rostering solutions. Check out our latest press releases, images & more."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper>
          <div className="-textCenter">
            <h1 className="-fontLarge">News & Media</h1>
          </div>
        </Wrapper>
      </Container>
      <Container bg="Tertiary" className="-whiteOut">
        <Wrapper maxWidth={1000} stackGap="0">
          <Row>
            <a
              css={{ padding: "20px" }}
              className="link-floating -center"
              href="#news"
            >
              News
            </a>
            <a
              css={{ padding: "20px" }}
              className="link-floating -center"
              href="#releases"
            >
              Media Releases
            </a>
            <a
              css={{ padding: "20px" }}
              className="link-floating -center"
              href="#downloads"
            >
              Downloads
            </a>
            <a
              css={{ padding: "20px" }}
              className="link-floating -center"
              href="#contact"
            >
              Contact
            </a>
          </Row>
        </Wrapper>
      </Container>
      <Container id="news">
        <Wrapper>
          <Box stackGap={60}>
            <h2 className="h1 -textCenter">FlexiTime in the News</h2>
            <PressReleaseList isWrap justify="flex-start">
              {contentfulData.allContentfulNewsArticle.edges.map((edge, i) => {
                return (
                  <PressReleaseCard centerOnMobile stackGap={30} key={i}>
                    <a
                      href={edge.node.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={{
                        position: "relative",
                        display: "block",
                      }}
                    >
                      <GatsbyImage
                        className="-center"
                        image={edge.node.image.gatsbyImageData}
                        alt={`${edge.node.title} | FlexiTime Press Release`}
                      />
                      <div
                        className="badge -clear"
                        css={{
                          position: "absolute!important",
                          bottom: "10px",
                          right: "10px",
                        }}
                      >
                        {edge.node.source}
                      </div>
                    </a>
                    <Box stackGap={30}>
                      <Box stackGap={10}>
                        <h4>
                          <b>{edge.node.title}</b>
                        </h4>
                        <p>{edge.node.description.description}</p>
                      </Box>
                      <h6 css={{ color: "#777" }}>
                        <IoIosCalendar css={{ margin: "0 7px -2px 0" }} />
                        {edge.node.date}
                      </h6>
                      <a
                        className="link-floating -center"
                        href={edge.node.linkTo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Article{" "}
                        <IoMdOpen css={{ marginLeft: "5px", top: "2px" }} />
                      </a>
                    </Box>
                  </PressReleaseCard>
                );
              })}
            </PressReleaseList>
          </Box>
          <hr id="releases" />
          <Box stackGap={60}>
            <h2 className="-textCenter h1">Media Releases</h2>
            <Box className="-center" css={{ maxWidth: "600px" }} stackGap={45}>
              {contentfulData.allContentfulMediaRelease.edges.map((edge, i) => {
                return (
                  <div key={i}>
                    <h4>
                      <span
                        className="-fontNormal"
                        css={{ color: "#999", fontSize: "1.1rem" }}
                      >
                        <IoIosCalendar
                          css={{ position: "relative", top: "2px" }}
                        />{" "}
                        {edge.node.date}
                      </span>
                      <br />
                      <Link
                        className="-fontBold link-floating"
                        css={{ marginTop: "5px" }}
                        to={`/media/${edge.node.url}`}
                      >
                        {edge.node.title}
                      </Link>
                    </h4>
                  </div>
                );
              })}
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Container className="-textCenter -whiteOut" bg="Tertiary" id="downloads">
        <Wrapper>
          <h2 className="h1">Downloads</h2>
          <Row stackGap={80}>
            <Box stackGap={30} size={50}>
              <div>
                <h3>
                  <IoIosContacts /> <br />
                  Media & Press Kit
                </h3>
                <p>
                  See the faces behind our products with the FlexiTime Media &
                  Press Kit. Get high quality photos of the FlexiTime team.
                </p>
              </div>
              <a
                className="link-floating -center"
                href="/media/Media & Press Kit.zip"
                download
              >
                Download
                <IoIosDownload
                  css={{ marginLeft: "8px", top: "3px", fontSize: "1.4rem" }}
                />
              </a>
            </Box>
            <Box stackGap={30} size={50}>
              <div>
                <h3>
                  <IoIosImages /> <br />
                  Product Logos
                </h3>
                <p>
                  Get high quality logos for FlexiTime & each of our products.
                  If you need a different logo size or format{" "}
                  <Link to="/contact">get in touch</Link>. Remember to follow
                  our{" "}
                  <a
                    href="/media/partners/FlexiTime_Brand_Guidelines.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    brand guidelines
                  </a>{" "}
                  too.
                </p>
              </div>
              <a
                className="link-floating -center"
                href="/media/Product_Logos.zip"
                download
              >
                Download
                <IoIosDownload
                  css={{ marginLeft: "8px", top: "3px", fontSize: "1.4rem" }}
                />
              </a>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container
        bg="orange"
        className="-textCenter -dark -whiteOut"
        id="contact"
        graphicalBR
      >
        <Wrapper stackGap={80}>
          <h2 className="h1">News & Press Contact</h2>
          <Box stackGap={30}>
            <Image
              filename="JakeHarvey_Circle.png"
              alt="Jake Harvey | Chief Growth Officer"
              width={80}
              centerImage
            />
            <h4>
              <span className="-fontBold">Jake Harvey</span>
              <br />
              Chief Growth Officer
            </h4>
            <h4 className="-fontNormal">
              <a href="mailto:jake@flexitime.works">
                <IoIosMail
                  css={{ position: "relative", top: "3px", marginRight: "7px" }}
                />
                jake@flexitime.works
              </a>
            </h4>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Media;
